.recommendationDate {
  margin-bottom: 1rem;
  font-weight: 500;
}

.treatmentDetails {
  margin: 1rem 0 2rem;

  .treatmentTitle {
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
}
