@import "~bootstrap/scss/bootstrap";
@import "font/feather";

@import "~clinic-hunter-styles/normalize";
@import "~clinic-hunter-styles/panel/theme-variables";
@import "~clinic-hunter-styles/panel/style";
@import "~clinic-hunter-styles/form-controls/style";
@import "~clinic-hunter-styles/emoji/emoji";
@import "./datepicker/styles";

.event-form-container {
  form {
    width: 100%;
  }
}

form {
  .insurance-date-form {
    .custom-date-row {
      padding: 1rem 0 10rem;

      .react-datepicker-host {
        .react-datepicker-popper {
          .react-datepicker {
            .react-datepicker__navigation {
              &.react-datepicker__navigation--next {
                border-left: none;
                right: 0;
                top: 0.2rem;

                  &:hover {
                    background: url("../images/arrow-right.png") no-repeat center;
                    opacity: 0.75;
                  }

                  &:focus {
                    border-left: none;
                  }
                }

              &.react-datepicker__navigation--previous {
                border-right: none;
                left: 0;
                top: 0.2rem;

                &:hover {
                  background: url("../images/arrow-left.png") no-repeat center;
                  opacity: 0.75;
                }

                &:focus {
                  border-right: none;
                }
              }

              &:focus {
                outline: none;
                border: 0.45rem solid transparent;
              }
            }

            .react-datepicker__month-container {
              .react-datepicker__header {
                margin-bottom: 0;
              }

              .react-datepicker__month {
                padding: 1rem;

                .react-datepicker__day {
                  margin: 0.2rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
